import { template as template_b3b04b61c56e489f9403eca8e2d397ea } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_b3b04b61c56e489f9403eca8e2d397ea(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_b3b04b61c56e489f9403eca8e2d397ea(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_b3b04b61c56e489f9403eca8e2d397ea(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
