import { template as template_9d8fb14e729a4cb3bea3f378dd1619c4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9d8fb14e729a4cb3bea3f378dd1619c4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
